import { ReactComponent as SendIcon } from "../../icons/paperPlane.svg";
import { ReactComponent as AttachIcon } from "../../icons/attach.svg";
import axios from "axios";
import { useState } from "react";
import {
  generateString,
  convertArrayToString,
  appURL,
  isAppDomain,
  PathImage,
} from "../../../libs";
import { useApp } from "../../../AppContext";

export default function ChatInput(props) {
  const { isAgent } = props;
  const [filePath, setFilePath] = useState();
  const { translations, chatbot, flowUser, setFlow, flowWait } = useApp();
  // console.log("flowWait", flowWait);

  const handleSubmitWithAnnotations = (
    url,
  ) => {
    const annotations = {
      type: 'image',
      data: { url },
    }
    props.append({
      content: props.input,
      role: "user",
      createdAt: new Date(),
      annotations,
    });
    props.setInput("");
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      props.setFile(file);
      const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
      if (!allowedTypes.includes(file.type)) {
        return;
      }
      const formData = new FormData();
      formData.append("file", file);
      await axios
        .post(`${appURL}/api/s3/upload/${chatbot._id}`, formData)
        .then((res) => {
          setFilePath(res.data.uri);
          props.setFilePath(res.data.uri);
        })
        .catch((err) => { });
    }
  };
  const hanldeGetFlow = () => {
    let FlowButton;
    const input = props.input.toLowerCase();
    FlowButton = props.flow?.actions?.find(
      (item) => item.name.toLowerCase() === input
    );
    if (FlowButton) {
      return FlowButton;
    } else {
      FlowButton = props.flow?.actions?.find(
        (item) => item.type === "condition" || item.type === "user"
      );
      return FlowButton;
    }
  };

  const handleSubmitForm = (event) => {
    event.preventDefault();
    if (isAgent) {
      const Array = props.messages.filter(
        (item) => item.content && item.content !== ""
      );

      Array.push({
        role: "user",
        content: props.input,
        id: generateString(8),
      });
      props.setMessages(Array);
      props.handleCreateMess(true, props.input, "user");
      setFilePath(undefined);
      props.setInput("");
    } else {
      // if (props.flow && props.flow?.actions?.length > 0) {
      if (flowUser) {
        // props.handleCreateMess(true, props.input, "user");
        // hanldeGetNextstep(hanldeGetFlow());
        props.handleSubmit(event);
        props.handleCreateMess(true, props.input, "user");
        setFilePath(undefined);
        setFlow(flowWait);
      } else {
        // if (filePath) {
        //   handleSubmitWithAnnotations(`${PathImage}${filePath}`)
        // }
        setFlow(undefined);
        props.handleSubmit(event);
        props.handleCreateMess(true, props.input, "user");
        setFilePath(undefined);
      }
    }
  };
  const CloseImage = () => {
    setFilePath(undefined);
    props.setFile(undefined);
    props.setFilePath(undefined);
  };
  // const hanldeGetNextstep = (flow) => {
  //   if (flow && flow.next_step) {
  //     const nextstep = props.flows.find(
  //       (item) => item._id === flow?.next_step
  //     );
  //     if (nextstep) {
  //       if (nextstep.isPrompt) {
  //         props.setMessResult((result) => [...result, nextstep.message]);
  //         props.setFlow(nextstep);
  //         const Array = props.messages.filter(
  //           (item) => item.content && item.content !== ""
  //         );
  //         const Message = [
  //           // { content: flow.name, id: generateString(8), role: "user" },
  //           { content: nextstep.message, id: generateString(8), role: "user" },
  //         ];
  //         props.setInput("");
  //         Array.push(...Message);
  //         props.setMessages(Array);
  //         props.reload();
  //       } else if (nextstep.message) {
  //         if (flow.type === "user" || flow.type === "condition") {
  //           props.setMessResult((result) => [...result, props.input]);
  //         }
  //         if (flow.type !== "user" || flow.type === "condition") {
  //           props.setMessResult((result) => [...result, flow.name]);
  //         }
  //         props.setFlow(nextstep);
  //         const Array = props.messages.filter(
  //           (item) => item.content && item.content !== ""
  //         );
  //         const Message = [
  //           { content: props.input, id: generateString(8), role: "user" },
  //           {
  //             content: nextstep.message,
  //             id: generateString(8),
  //             role: "assistant",
  //           },
  //           // { content: undefined, id: generateString(8), role: "user" },
  //         ];

  //         props.setInput("");
  //         Array.push(...Message);
  //         props.setMessages(Array);
  //         props.handleCreateMess(true, props.input, "user");

  //         // props.reload();
  //       } else {
  //         const Array = props.messages.filter(
  //           (item) => item.content && item.content !== ""
  //         );

  //         Array.push({
  //           role: "user",
  //           content: convertArrayToString([...props.messResult, props.input]),
  //           id: generateString(8),
  //         });
  //         props.setMessages(Array);
  //         props.reload();
  //         props.setFlow(nextstep);
  //         props.setMessResult([]);
  //         // props.setFlow();
  //         props.setInput("");
  //       }
  //     }
  //   } else {
  //     const Array = props.messages.filter(
  //       (item) => item.content && item.content !== ""
  //     );
  //     Array.push({
  //       role: "user",
  //       content: props?.input,
  //       id: generateString(8),
  //     });
  //     props.setMessages(Array);
  //     props.reload();
  //     props.setMessResult([]);
  //     props.setInput("");
  //     props.setFlow();
  //   }
  // };

  return (
    <>
      <form
        onSubmit={handleSubmitForm}
        className={`h-[15%] flex items-center justify-between w-full max-w-5xl px-4 py-2 border-t border-[#f1f1f1]`}
      >
        <label className="" htmlFor="upload-image">
          <div className="upload_image">
            <AttachIcon className="w-[22px] text-gray-400" />
            <input
              type="file"
              id="upload-image"
              name="image"
              className="hidden"
              onChange={handleFileChange}
            />
          </div>
        </label>
        <div className="w-[80%] relative flex items-center">
          {filePath && (
            <div className="group">
              <button
                onClick={CloseImage}
                type="button"
                size={16}
                style={{ color: "#979c9e" }}
                className="absolute left-8 bottom-6 cursor-pointer opacity-0 group-hover:opacity-100"
              >
                x
              </button>
              <img
                className="h-[30px]"
                style={{ width: "30px" }}
                src={`${PathImage}${filePath}`}
              ></img>
            </div>
          )}
          <input
            autoFocus
            name="message"
            placeholder={`${props?.textPlaceholder
              ? props?.textPlaceholder
              : translations?.type_a_message
              }`}
            autoComplete="off"
            className={`w-full p-3 outline-none rounded-xl`}
            value={props.input}
            onChange={props.handleInputChange}
          />

        </div>
        <button
          disabled={!props.lead?.botOn}
          type="submit"
          className={`p-2 rounded-xl`}
        >
          <SendIcon
            style={{ color: chatbot?.chatbotColor || "#3047EC" }}
            className="w-[24px] cursor-pointer"
          />
        </button>
      </form>
    </>
  );
}
