import React, { createContext, useState, useContext, ReactNode } from "react";
import { translations, LanguageCode } from "./libs/translations";

interface IFlow {
  message: string;
  index: number;
  isPrompt: boolean;
}

interface IPrompt {
  prompt: string;
  text: string;
}

interface ILink {
  url: string;
  uri: string;
}

interface IChat {
  chatModel: "gpt-3.5" | string;
  chatbotColor: string;
  chatbotIcon: string;
  flows: IFlow[];
  links: ILink[];
  isAgent: boolean;
  isLead: boolean;
  language: string;
  menu: any[];
  name: string;
  pageId: string;
  oaId: string;
  prompts: IPrompt[];
  textPlaceholder: string;
  translate: any[];
  usageMessage: number;
  welcomeMessage: string;
  whatsappNumber: string;
}

// Define the shape of the context
interface AppContextType {
  language: LanguageCode;
  changeLanguage: (lang: LanguageCode) => void;
  translations: Record<string, string>;
  setBot: (bot: IChat) => void;
  setFlow: (data: any) => void;
  changeFlowWait: (data: any) => void;
  changeFlowUser: (data: any) => void;
  setInbox: (id: string) => void;
  setCurrentPage: (p: string) => void;
  setNotifi: any;
  setIschat: any;
  chatbot: IChat;
  inboxId: string;
  page: string;
  flow: any;
  flowWait: any;
  flowUser: any;
  notifi: boolean;
  ischat: boolean;
}

// Create context with default values
const AppContext = createContext<AppContextType | undefined>(undefined);

interface AppProviderProps {
  children: ReactNode;
}

// Provider component
export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [language, setLanguage] = useState<LanguageCode>("en");
  const [inboxId, setInboxId] = useState<string>("");
  const [page, setPage] = useState<string>("init");
  const [flow, setflow] = useState<any>();
  const [flowWait, setFlowWait] = useState<any>();
  const [flowUser, setFlowUser] = useState<any>();
  const [notifi, setNotifi] = useState(false);
  const [ischat, setIschat] = useState(false);

  // Initialize with a default IChat object
  const [chatbot, setChatbot] = useState<IChat>({
    chatModel: "gpt-3.5",
    chatbotColor: "#3461ee",
    chatbotIcon: "",
    flows: [],
    isAgent: true,
    isLead: true,
    language: "en",
    menu: [],
    links: [],
    name: "TaggoAI",
    pageId: "",
    oaId: "",
    prompts: [],
    textPlaceholder: "Type a message",
    translate: [],
    usageMessage: 0,
    welcomeMessage: "I'm your AI assistant, how can I help you?",
    whatsappNumber: "",
  });

  // Function to change language
  const changeLanguage = (lang: LanguageCode) => {
    setLanguage(lang);
  };

  const setBot = (bot: IChat) => {
    setChatbot(bot);
  };
  const setFlow = (data: any) => {
    setflow(data);
  };
  const changeFlowWait = (data: any) => {
    setFlowWait(data);
  };
  const changeFlowUser = (data: any) => {
    setFlowUser(data);
  };

  const setInbox = (id: string) => {
    setInboxId(id);
  };

  const setCurrentPage = (p: string) => {
    setPage(p);
  };

  return (
    <AppContext.Provider
      value={{
        language,
        chatbot,
        page,
        setCurrentPage,
        inboxId,
        changeLanguage,
        setBot,
        setInbox,
        translations: translations[language],
        setFlow,
        flow,
        flowWait,
        changeFlowWait,
        flowUser,
        changeFlowUser,
        notifi,
        setNotifi,
        ischat,
        setIschat,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

// Custom hook to use language context
export const useApp = (): AppContextType => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useApp must be used within a AppProvider");
  }
  return context;
};
