import ChatAvatar from "./chat-avatar";
import Markdown from "react-markdown";
import { useSearchParams } from "react-router-dom";
import { isImageLink, PathImage, generateString } from "../../../libs";
import { useApp } from "../../../AppContext";
import { Button } from "../element/button";
export default function ChatItem(props) {
  const { message, dataBot, handleCreateMess, setMessages, mess, reload, isLoading } = props;
  const { chatbot, translations } = useApp();
  let [searchParams] = useSearchParams();
  let shopId = searchParams.get("shopId");
  const color = dataBot?.chatbotColor ? dataBot?.chatbotColor : "#3047EC";
  const renderers = {
    a: (props) => {
      let { href, children } = props;
      if (isImageLink(href)) {
        return <img src={href} className="list-image-none mb-3" />;
      } else {
        return (
          <a
            href={href}
            className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
            target={shopId ? "_parent" : "_blank"}
            rel="noopener noreferrer"
          >
            {children}
          </a>
        );
      }
    },
  };

  const getSources = (annotations) => {
    const sources = annotations.find(e => e.type === "sources");
    const data = sources.data?.nodes ? sources.data?.nodes[0] : null;
    const cleanUrl = data?.metadata?.file_name
      ? data?.metadata?.file_name.replace(/\.txt$/, '') : null;
    const dataUrl = chatbot.links.find(e => e.uri === cleanUrl);
    return data ? (
      <div className="flex justify-end text-sm mr-2 mt-1">
        {dataUrl ? <a target="_blank" href={dataUrl?.url} className="flex items-center gap-1">
          <img src="./icon/link-outline.svg" width={22} height={22} />
          {/* <span className="text-sm">{translations?.source}</span> */}
          <div className="hidden- max-w-[140px] text-[12px] truncate">{dataUrl?.title || ""}</div>
        </a> : <>
          {data?.metadata?.file_name && <div className="flex items-center gap-1">
            <span className="font-bold">{data?.metadata?.file_name?.slice(9)}</span>
          </div>}</>}
      </div>
    ) : null;
  }

  const getSuggested = (annotations) => {
    const sources = annotations.find(e => e.type === "suggested_questions");
    return sources ? (
      <div className="flex justify-end items-end mt-2 text-sm p-2">
        <div>
          {sources?.data.map((text, index) => (
            <div className="mb-2 flex justify-end">
              <Button key={index} onClick={() => handleCreate(text)} label={text} />
            </div>
          ))}
        </div>
      </div>
    ) : null;
  }

  const handleCreate = (message) => {
    const array = mess.filter(
      (item) => item.content && item.content !== ""
    );
    let arrays = [
      ...array,
      {
        role: "user",
        content: message,
        id: generateString(8),
      }
    ];

    setMessages(arrays);
    handleCreateMess(true, message, "user")
    reload();
  }

  return (
    <div>
      <div
        className={`flex items-start gap-2 pe-2 ${message.role === "user" ? "justify-end ml-2" : "justify-start"
          }`}
      >
        <div
          style={{ backgroundColor: message.role === "user" ? color : "#f5f6f8" }}
          className={`flex flex-col rounded p-3 ${message.role === "user"
            ? `order-0 text-white`
            : "order-1"
            }`}
        >
          <Markdown className="break-words" components={renderers}>
            {message.content}
          </Markdown>
          {message?.file?.uri && (
            <img
              className="w-[100px] pt-3"
              src={`${PathImage}${message.file.uri}`}
            ></img>
          )}
        </div>
        <ChatAvatar {...message} />
      </div>
      {!isLoading && message.role === 'assistant' && message.annotations && (
        getSources(message.annotations)
      )}
      {!isLoading && message.role === 'assistant' && message.annotations && (
        getSuggested(message.annotations)
      )}
    </div>
  );
}
